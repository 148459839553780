import React, { useEffect } from 'react';

export default function KuulaProject(props) {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				frameBorder="0" allowFullScreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" 
				src="https://kuula.co/share/collection/7YYC5?logo=-1&info=0&fs=1&vr=1&sd=1&thumbs=-1&inst=0"></iframe>
    	</div>
	)
}
