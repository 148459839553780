import React, { useState, useEffect, useContext } from 'react';
import ReactPlayer from 'react-player';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faBookmark, faFolder, faCheckCircle, faFileAudio,
	faPlayCircle, faQuestionCircle, faTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import { Row, Col, Navbar, Nav, Tab, Modal, Toast, Image } from 'react-bootstrap';

import KuulaProject from './components/KuulaProject';

import './styles/App.css';
import './styles/TabStyles.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

// site rooms indicate rooms in the offcanvas menu (label, order, visited)
const site_rooms = [
	{
		"id": 1,
		"order": 0,
		"section": 1,
		"kuula_scene_id": "7RG9Z",
		"title": "Terminal",
		"description": "",
		"img_loc": "",
		"visited": true
	},
	{
		"id": 2,
		"order": 1,
		"section": 1,
		"kuula_scene_id": "Nb4BK",
		"title": "Arcade",
		"description": "",
		"img_loc": "",
		"visited": false
	},
	{
		"id": 3,
		"order": 2,
		"section": 1,
		"kuula_scene_id": "Nb7Tz",
		"title": "Lounge",
		"description": "",
		"img_loc": "",
		"visited": false
	},
	{
		"id": 4,
		"order": 3,
		"section": 1,
		"kuula_scene_id": "Nb4Bb",
		"title": "Gift Shop",
		"description": "",
		"img_loc": "",
		"visited": false
	}
]

const audioPlaylist = [
	{ id: 1, title: "Young Thug - Bubbly (with Drake & Travis Scott)", file_loc: "young_thug_-_bubbly_with_drake_travis_scott.mp3" },
	{ id: 2, title: "Gunna & Future - Pushin P (Clean)",  file_loc: "gunna_future_-_pushin_p_clean.mp3" },
	{ id: 3, title: "Young Thug & Gunna - Ski",  file_loc: "young_thug_&_gunna_-_ski.mp3" },
	{ id: 4, title: "Young Thug - Warrior",  file_loc: "young_thug_-_warrior.mp3" }
];
  
// nav points are for hotspots that navigate user to different rooms
const nav_points = [
	// blueprint hotspot links
	{ "hotspot_name": "terminal", "kuula_scene_id": "7RG9Z" },
	{ "hotspot_name": "arcade", "kuula_scene_id": "Nb4BK" },
	{ "hotspot_name": "lounge", "kuula_scene_id": "Nb7Tz" },
	{ "hotspot_name": "gift_shop", "kuula_scene_id": "Nb4Bb" },
	
	// terminal
	{ "hotspot_name": "room_1_to_2", "kuula_scene_id": "Nb4BK" },
	{ "hotspot_name": "room_1_to_3", "kuula_scene_id": "Nb7Tz" },
	{ "hotspot_name": "room_1_to_4", "kuula_scene_id": "Nb4Bb" },
  
	// arcade
	{ "hotspot_name": "room_2_to_1", "kuula_scene_id": "7RG9Z" },
  
	// lounge
	{ "hotspot_name": "room_3_to_1", "kuula_scene_id": "7RG9Z" },

	// gift shop
	{ "hotspot_name": "room_4_to_1", "kuula_scene_id": "7RG9Z" }
]

const site_items = [
	{ id: 1,  hotspot_name: "easter_egg_1",  img_loc: "terminal_300.png",       "title": "300",					"description": "", "visible": false },
	{ id: 2,  hotspot_name: "easter_egg_2",  img_loc: "terminal_YSL.png",       "title": "Young Stoner Life",   "description": "", "visible": false },
	{ id: 3,  hotspot_name: "easter_egg_3",  img_loc: "terminal_arcade.png",    "title": "Arcade",    			"description": "", "visible": false },
	{ id: 4,  hotspot_name: "easter_egg_4",  img_loc: "terminal_directory.png", "title": "Directory", 			"description": "", "visible": false },
	{ id: 5,  hotspot_name: "easter_egg_5",  img_loc: "terminal_gift_shop.png", "title": "Gift Shop", 			"description": "", "visible": false },
	{ id: 6,  hotspot_name: "easter_egg_6",  img_loc: "terminal_greetings.png", "title": "Greetings", 			"description": "", "visible": false },
	{ id: 7,  hotspot_name: "easter_egg_7",  img_loc: "terminal_lounge.png",    "title": "Lounge",    			"description": "", "visible": false },

	{ id: 8,  hotspot_name: "easter_egg_8",  img_loc: "lounge_gunna.png",       "title": "Gunna",      			"description": "", "visible": false },
	{ id: 9,  hotspot_name: "easter_egg_9",  img_loc: "lounge_stoner.png",      "title": "Stoner",     			"description": "", "visible": false },
	{ id: 10, hotspot_name: "easter_egg_10", img_loc: "lounge_tinydesk.png",    "title": "Tiny Desk",  			"description": "", "visible": false },

	{ id: 11, hotspot_name: "easter_egg_11", img_loc: "arcade_freenft.png",     "title": "Free NFT",   			"description": "", "visible": false },
	{ id: 12, hotspot_name: "easter_egg_12", img_loc: "arcade_punk.png",        "title": "Punk",       			"description": "", "visible": false },
	{ id: 13, hotspot_name: "easter_egg_13", img_loc: "arcade_tooeasy.png",     "title": "Too Easy",   			"description": "", "visible": false },
	{ id: 14, hotspot_name: "easter_egg_14", img_loc: "arcade_trapped.png",     "title": "Trapped",    			"description": "", "visible": false },
	{ id: 15, hotspot_name: "easter_egg_15", img_loc: "arcade_waverunner.png",  "title": "Waverunner", 			"description": "", "visible": false }
]

export default function App() {
	// const { tour } = useTour();

	// const klaviyoOnsite = JSON.parse(localStorage.getItem('klaviyoOnsite'));
	const [ gate, setGate ] = useState('closed');

	const [ show, setShow ] = useState(true);
	const [ firstLoad, setFirstLoad ] = useState(true);
	const [ modalContent, setModalContent ] = useState('ticket'); // ticket or email_form

	// const [ toastShow, setToastShow ] = useState(false);
	const [ toastList, setToastList ] = useState([]);

	const [ offcanvas, setOffcanvas ] = useState('show');
	const [ tabSelected, setTab ] = useState('audio');

	const [ audioList, setPlayList ] = useState(audioPlaylist);
	const [ selectedTrackID, setSelectedTrack ] = useState(1);
	const [ audio_playing, setAudioPlaying ] = useState(false);
	const [ chosenTrack, setChosenTrack ] = useState(audioPlaylist[0].file_loc);

	const [ rooms, setRooms ] = useState(site_rooms);
	const [ roomCount, setRoomCount ] = useState(1);
	const [ current_room, setCurrentRoom ] = useState(site_rooms[0].kuula_scene_id);

	const [ items, setItems ] = useState([ ...site_items ]);
	const [ itemCount, setItemCount ] = useState(0);

	const [ hotspot, setSelectedHotspot ] = useState('');

	function toggleCanvas() {
		if ( offcanvas === '' )
			setOffcanvas('show')
		else 
			setOffcanvas('')
	}

	function handleClose() {
		console.log('inside handleClose function')

		setShow(false);
		setModalContent("ticket");
		if ( firstLoad ) {
			setFirstLoad(false);
			setAudioPlaying(true);
		}
	}

	// useEffect(() => {

	// 	if ( modalContent === 'email_form' && klaviyoOnsite?.viewedForms?.modal?.disabledForms?.UUZR9p?.successActionTypes[0] == "SUBMIT_TO_LIST_AND_TRANSITION_VIEW" ) {
	// 		console.log('calling handleClose')
	// 		setTimeout(() => {
	// 			handleClose()
	// 		}, 5000);
	// 	}

	// }, [ klaviyoOnsite?.viewedForms.modal.disabledForms ]);

	useEffect(() => {

		if ( localStorage.getItem('eggs') ) {
			let count = 0;
			var localEggs = JSON.parse(localStorage.getItem('eggs'));
			var updated_items = localEggs?.map(item => {
				if (item.visible) count++;
				return item;
			})
			setItemCount(count);
			setItems([ ...updated_items ])
		}

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			// console.log('hotspot data id', e.data.uid);
			// console.log('hotspot data name', e.data.name);
			setSelectedHotspot(e.data.name);
		});
  
	}, []);

	// useEffect(() => {
	// 	if ( modalContent !== 'ticket' ) {
	// 		const script = document.createElement('script');

	// 		script.src = "//static.klaviyo.com/onsite/js/klaviyo.js?company_id=WvXpRn";
	// 		script.async = true;

	// 		document.body.appendChild(script);

	// 		return () => {
	// 			document.body.removeChild(script);
	// 		}
	// 	}
	// }, [modalContent])
  
	useEffect(() => {
		if ( hotspot !== '' ) {
			// check if item clicked on
			let new_count = itemCount;
			
			var new_toast = {}
			var new_items = items.map(item => {
				if ( item.hotspot_name === hotspot && !item.visible ) {
					new_count++;
					// add item to toast list
					new_toast = {
						'id': item.id,
						'message': 'Hidden Item Found!',
						'item_name': item.title,
						'img': item.img_loc
					}
					return { ...item, visible: true }
				}
				return { ...item };  
			});

			if ( new_count > itemCount ) {

				setItemCount(new_count);
				setItems([ ...new_items ]);
				localStorage.setItem('eggs', JSON.stringify(new_items))
				setToastList([
					...toastList,
					new_toast
				])

			} else {

				// if not item, check if room navigation clicked on
				var nav_found = nav_points.find(nav => nav.hotspot_name === hotspot);
				if (nav_found) {
					// console.log('room_found', room_found)
					openTourScene(nav_found.kuula_scene_id)
				}

			}
		}
	}, [hotspot])
  
	function openTourScene(scene_id){
		var visitedCount = 0;
		var new_rooms = rooms.map(room => {
			if ( room.kuula_scene_id === scene_id ) {
				room.visited = true
			}
			if ( room.visited ) {
				visitedCount++;
			}
			return room;
		});
		
		setCurrentRoom(scene_id);
		setRooms(new_rooms);
		setRoomCount(visitedCount)

		KuulaPlayerAPI.load(frame_id, scene_id);
	}
  
	function showInstructions(e) {
		e.preventDefault();
		setShow(true);
	}
  
	function clearFoundItems() {
		localStorage.removeItem('eggs');
		localStorage.setItem('eggs', JSON.stringify([ ...site_items ]));
		setItems([ ...site_items ]);
		setItemCount(0);
		setSelectedHotspot('')
	}
  
	function removeToastFromList(toast_id) {
		var removeItemIndex = toastList.findIndex(toast => toast.id === toast_id);
		var newToastList = [ ...toastList ];
		newToastList.splice(removeItemIndex, 1);
		setToastList(newToastList);
	}
  
	function playAudio(audio_id) {
		setSelectedTrack(audio_id)
		var track_index = audioList.findIndex(audio => audio.id === audio_id);
		setChosenTrack(audioList[track_index].file_loc)
		setAudioPlaying(true)
	}
	/*
	function playPauseAudio() {
	  setAudioPlaying(!audio_playing)
	}
	*/
	function playNextTrack() {
		var current_track_index = audioList.findIndex(audio => audio.id === selectedTrackID)
		if ( current_track_index < audioList.length ) {
			setSelectedTrack(audioList[current_track_index + 1].id)
			setChosenTrack(audioList[current_track_index + 1].file_loc)
			setAudioPlaying(true)
		}
	}

	function showHideEmail() {
		setModalContent('email_form');
	}

	return (
		<div className="App">

			<Modal id="welcome-modal" show={show} onHide={handleClose}>
				<Modal.Body>
					<FontAwesomeIcon icon={faTimesCircle} className="close-modal-times" onClick={handleClose} />
					{modalContent === 'ticket' ? (
						<>
							<img src={`images/greetings_isa_new.jpg`} alt="Instructions" style={{ width: '100%' }} />
							<button className="modal-close-button" onClick={handleClose}>
								TAP TO ENTER
							</button>
						</>
					) : (
						<div className="klaviyo-form-UUZR9p"></div>
					)}
				</Modal.Body>
				{modalContent === 'ticket' && (
					<Modal.Footer>	
						<p>
							Get the best of cannabis, hip-hop, and streetwear culture in your email! Join the ISA Newsletter:
						</p>
						<button className="modal-enter-button" onClick={showHideEmail}>
							SUBSCRIBE
						</button>
					</Modal.Footer>
				)}
			</Modal>

			<Navbar variant="dark" bg="transparent" fixed="bottom" expand={false} defaultExpanded={true}>
				<Navbar.Toggle className="p-0 border-0" data-toggle="offcanvas" onClick={toggleCanvas} />
				<Navbar.Collapse id="offcanvas_navigation">
					<Tab.Container activeKey={tabSelected} onSelect={(k) => setTab(k)}>
						<Row id="tour-title-row">
							<Col>
								<Image src={`images/isa-logo.jpg`} alt="International Stoners Association" fluid className="px-4" />
								<div className="float-right mr-3">
									<a href="#" onClick={(e) => showInstructions(e)} style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }}>
										Instructions
										<FontAwesomeIcon icon={faQuestionCircle} className="ml-2" />
									</a>
								</div>
							</Col>
						</Row>
						<Row id="tab-row">
							<Col>
								<Nav justify variant="tabs" defaultActiveKey="rooms" style={{ flexDirection: 'row', maxWidth: '400px' }}>
									<Nav.Item>
										<Nav.Link eventKey="audio">
											<FontAwesomeIcon icon={faFileAudio} />
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="rooms">
											<FontAwesomeIcon icon={faFolder} /> <span id="rooms_visited">{roomCount}</span>/<span id="rooms_total">{rooms.length}</span>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="items">
											<FontAwesomeIcon icon={faBookmark} /> <span id="items_found">{itemCount}</span>/<span id="items_total">{items.length}</span>
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
						</Row>
						<Row style={{ margin: 0 }}>
							<Col>
								<Tab.Content>
									<Tab.Pane transition={false} eventKey="audio">
										<div id="audio_player">
											<ReactPlayer
												className='react-player'
												url={'audio/' +chosenTrack}
												width='100%'
												height='100%'
												playing={audio_playing}
												controls={true}
												onEnded={playNextTrack}
												style={{ height: 'revert' }}
												// Disable download button
												config={{ file: { attributes: { controlsList: 'nodownload' } } }}
												// Disable right click
												onContextMenu={e => e.preventDefault()}
											/>
										</div>
										<ol id="audio_list" className="navbar-nav mr-auto">
											{audioList?.map(audio => {
												return (
													<li key={audio.id} className={'nav-item'}>
														<a id={`audio_${audio.id}`} className={'nav-link' + (audio.id === selectedTrackID ? ' active' : '')} onClick={(e) => playAudio(audio.id)}>
															{audio.id === selectedTrackID && (
																<FontAwesomeIcon icon={faPlayCircle} className="mr-2" />
															)}
															{audio.title}
														</a>
													</li>
												)
											})}
										</ol>
									</Tab.Pane>
									<Tab.Pane transition={false} eventKey="rooms">
										<ul id="rooms" className="navbar-nav mr-auto pl-3 mt-3">
											{rooms?.map((room, idx) => {
												return (
													<div key={idx}>
														{room.id === 7 && (
															<hr style={{ display: 'block', height: '1px',
																border: '0', borderTop: '1px solid #ccc',
																margin: '0 0', padding: '0' }} />
														)}
														<li key={room.id} id={`menu_${room.kuula_scene_id}`} className={'nav-item' + (room.visited ? ' visited' : '')}>
															{room.status === "inactive" ? (
																<a id={`room_${room.kuula_scene_id}`} className={'nav-link disabled'} >
																	{room.title}
																</a>
															) : (
																<a id={`room_${room.kuula_scene_id}`} className={'nav-link' + (room.kuula_scene_id === current_room ? ' active' : '')} onClick={(e) => openTourScene(room.kuula_scene_id)}>
																	{room.visited && (
																		<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
																	)}
																	{room.title}
																	{room.section === 2 && (
																		<span className="ml-2" style={{ fontSize: '.8em' }}>( New )</span>
																	)}
																</a>
															)}
														</li>
													</div>
												)
											})}
										</ul>
									</Tab.Pane>
									<Tab.Pane transition={false} eventKey="items">
										{items.length && (
											<div className="tab-pane-instr">
												Can you find all the Hidden Items?<br /> Clicking the items in the 360 viewer will reveal them here. Hover over the item in the menu for a Hint
												{itemCount > 0 && (
													<div>
														[ <a style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }} onClick={() => { clearFoundItems() }}>Clear Found Items</a> ]
													</div>
												)}
											</div>
										)}
										<div id="items">
											<Row>
												{items?.map((item, idx) => 
													item.visible ? (
														<Col key={idx} xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/items/${item.img_loc}`} alt="found item" className="item_thumb" title={item.title} fluid />
														</Col>
													) : (
														<Col key={idx} xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/item_hidden.png`} alt="hidden item" className="item_thumb" title={'Hint: ' +item.title} fluid />
														</Col>
													)
												)}
											</Row>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Navbar.Collapse>
			</Navbar>

			<div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000' }}>
				{toastList?.map(toast => {
					return (
						<Toast key={toast.id} onClose={() => removeToastFromList(toast.id)} show={true} delay={7000} autohide>
							<Toast.Header>
								<FontAwesomeIcon icon={faBookmark} className="mr-3" />
								<strong className="mr-auto">{toast.message}</strong>
							</Toast.Header>
							<Toast.Body style={{ textAlign: 'left' }}>
								<img src={'images/items/' +toast.img} className="rounded mr-2" style={{ height: '50px', width: '50px' }} alt="" />
								{toast.item_name}
							</Toast.Body>
						</Toast>
					)
				})}
			</div>

			<KuulaProject />

		</div>
	)
}